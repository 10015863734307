



































































































import { TeacherTermDto } from "@/models/planning/TeacherTermDto";
import Vue from "vue";
import { mapGetters } from "vuex";
import { FormValidationRequired, FormValidationNoLessThan, FormValidationRange } from "@/models/core/FormValidation"
import { TeacherDto } from "@/models/planning/TeacherDto";
import { SchoolTermDto } from "@/models/planning/SchoolTermDto";
import { EmploymentTypeDto } from "@/models/planning/EmploymentTypeDto";
import { EducationalDirectionDto } from "@/models/planning/EducationalDirectionDto";
import { DepartmentDto } from "@/models/planning/DepartmentDto";

function copyObject<T>(toCopy: T) {
  const asString = JSON.stringify(toCopy);
  return JSON.parse(asString) as T;
}

type TermErrors = {
  relSchoolTermNavigation?: FormValidationRequired | undefined;
  relEmploymentTypeNavigation?: FormValidationRequired | undefined;
  relEducationalDirectionNavigation?: FormValidationRequired | undefined;
  relDepartmentNavigation?: FormValidationRequired | undefined;
  teachingFactor?: (FormValidationRequired & FormValidationRange) | undefined
  employmentFactor?: (FormValidationRequired & FormValidationRange & FormValidationNoLessThan) | undefined
}

export default Vue.extend({
  name: "edit-term",
  props: ["value", "existing"],
  data: () => ({
    isMenuOpen: true,
    educators: [],
    term: {},
    selectableSchoolTerms: [],
    errors: {} as TermErrors
  }),
  computed: {
    ...mapGetters("teachers", ["teachers"]),
    ...mapGetters("schoolTermStore", ["schoolTerms"]),
    ...mapGetters("departments", ["departments"]),
    ...mapGetters("employmentTypesStore", ["employmentTypes"]),
    ...mapGetters("educationDirectionsStore", ["educationDirections"]),
    ...mapGetters("teacherTermsStore", ["teacherTerms", "isLoading"]),
    educationDirectionsForTeacherTerm() {
      return this.educationDirections('teacherterm');
    },
    termRelTeacherNavigation: {
      get() {
        return this.term?.relTeacherNavigation ?? {};
      },
      set(value:TeacherDto) {
        this.$set(this.term, 'relTeacherNavigation', value);
        this.$set(this.term, 'relTeacher', value.id);
      }
    },
    termRelSchoolTermNavigation: {
      get() {
        return this.term?.relSchoolTermNavigation ?? {};
      },
      set(value:SchoolTermDto) {
        this.$set(this.term, 'relSchoolTermNavigation', value);
        this.$set(this.term, 'relSchoolTerm', value.id);
      }
    },
    termRelEmploymentTypeNavigation: {
      get() {
        return this.term?.relEmploymentTypeNavigation ?? {};
      },
      set(value:EmploymentTypeDto) {
        this.$set(this.term, 'relEmploymentTypeNavigation', value);
        this.$set(this.term, 'relEmploymentType', value.id);
      }
    },
    termRelEducationalDirectionNavigation: {
      get() {
        return this.term?.relEducationalDirectionNavigation ?? {};
      },
      set(value:EducationalDirectionDto) {
        this.$set(this.term, 'relEducationalDirectionNavigation', value);
        this.$set(this.term, 'relEducationalDirection', value.id);
      }
    },
    termRelDepartmentNavigation: {
      get() {
        return this.term?.relDepartmentNavigation ?? {};
      },
      set(value:DepartmentDto) {
        this.$set(this.term, 'relDepartmentNavigation', value);
        this.$set(this.term, 'relDepartment', value.id);
      }
    },
    termEmploymentFactor: {
      get() {
        return this.term?.employmentFactor ?? 100;
      },
      set(value:Number) {
        this.$set(this.term, 'employmentFactor', value);
      }
    },
    termTeachingFactor: {
      get() {
        return this.term?.teachingFactor ?? 100;
      },
      set(value:Number) {
        this.$set(this.term, 'teachingFactor', value);
      }
    },
    termComment: {
      get() {
        return this.term?.comment ?? '';
      },
      set(value:string) {
        this.$set(this.term, 'comment', value);
      }
    }
  },
  methods: {
    handleTeachingFactor() {
      if (this.term.teachingFactor == null || this.term.employmentFactor == null) return;

      if (this.term.employmentFactor < this.term.teachingFactor) {
        this.term.teachingFactor = this.term.employmentFactor;
      }
    },
    createSemester() {
      this.isMenuOpen = true;
    },
    close() {
      if (this.isLoading) return;

      this.isMenuOpen = false;
      this.$emit("close");
      this.term = null;
    },
    addTerm(event: Event) {
      event.preventDefault();

      const errors = this.validateTerm(this.term);

      this.errors = errors;

      if (Object.keys(errors).length === 0) {
        // console.log("save");
      this.$emit("save", this.term);
      this.close();
      }
    },
    validateTerm(term: TeacherTermDto) {
      const errors = { } as TermErrors;


      const requiredField = [
        "relSchoolTermNavigation",
        "relEmploymentTypeNavigation",
        "relEducationalDirectionNavigation",
        "relDepartmentNavigation",
        "employmentFactor",
        "teachingFactor",
      ];

      for (const missingField of requiredField.filter(field => (term[field] === undefined || term[field] === ''))) {
        if (!errors[missingField])
          errors[missingField] = {};

        errors[missingField].required = "Dette felt mangler";
      }

      if (this.term.employmentFactor < this.term.teachingFactor) {
        if (!errors.employmentFactor) 
          errors.employmentFactor = {};

        this.errors.employmentFactor.noLessThan = "Undervisningsgrad må ikke være mindre end ansættelsesgrad";
      }


      const rangeFields = [
        "employmentFactor",
        "teachingFactor"
      ];

      for (const rangeField of rangeFields) {
        const value = term[rangeField] as number;
  
        if (0 <= value && value <= 100)
          continue;

        if (!errors[rangeField])
          errors[rangeField] = {};

        errors[rangeField].range = "Der er ikke valgt mellem 0% og 100%";
      } 

      return errors;
    }
  },
  watch: {
    'value'(newValue) {
      this.term = copyObject(newValue);

      // To prevent people from selecting the same terms twice
      this.selectableSchoolTerms = this.schoolTerms.filter(
        st => this.teacherTerms.find(tc => tc.relSchoolTerm === st.id) == null
      );
    }
  }
});
