
























































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EditTerm from "./Terms/EditTerm.vue";
import { TeacherTermDto } from "@/models/planning/TeacherTermDto";
import { TeacherDto } from "@/models/planning/TeacherDto";

function createTerm(teacher: TeacherDto) {
  const newTerm = {
    relTeacherNavigation: teacher,
    employmentFactor: 100,
    teachingFactor: 100,
    comment: "",
  } as TeacherTermDto;

  return newTerm;
}

export default Vue.extend({
  name: "teacher-terms",
  components: {
    EditTerm,
  },
  data: () => ({
    term: null as TeacherTermDto | null,
    isSidebarOpen: false,
    educators: [],
    existing: false as boolean,
    deleting: undefined as TeacherTermDto|undefined,
  }),
  async beforeMount() {
    await this.getAllForTeacher(this.selectedTeacher.id);
  },
  computed: {
    ...mapGetters("teachers", ["teachers", "selectedTeacher"]),
    ...mapGetters("schoolTermStore", ["schoolTerms"]),
    ...mapGetters("departments", ["departments"]),
    ...mapGetters("employmentTypesStore", ["employmentTypes"]),
    ...mapGetters("teacherTermsStore", ["teacherTerms", "isLoading"]),
  },
  methods: {
    ...mapActions("teacherTermsStore", ["saveTerms", "getAllForTeacher", "updateTerms", "removeTeacherTerm"]),
    ...mapActions("educationDirectionsStore", ["loadEducationalDirectionsByKey"]),
    createSemester() {
      this.term = createTerm(this.selectedTeacher);
      this.existing = false;
      this.isSidebarOpen = true;
    },
    async saveTerm(term: TeacherTermDto) {
      if (this.existing) {
        await this.updateTerms(term);
      }
      else {
        await this.saveTerms(term);
      }
      this.closeSidebar();
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      this.existing = false;
    },
    onRowClick(teacherTerm:TeacherTermDto) {
      this.term = {...teacherTerm};
      this.existing = true;
      this.isSidebarOpen = true;
    },
    async remove(controls) {
      try {
        await this.removeTeacherTerm(this.deleting);
        controls.close();
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved slet af semester-tilknytning',
          type: 'is-danger'
        });
      }
    }

  },
  watch: {
    async 'isSidebarOpen'(isOpen) {
      if (isOpen) {
        await this.loadEducationalDirections();
      }
    }
  }
});
